<template>
    <div>
        <styled-dialog v-model="displayDeleteLeadDestinationModal">
            <template #heading>
                <h2>Delete Lead Destination</h2>
            </template>
            <v-alert
                :value="error"
                type="error"
                transition="fade-transition"
                class="mb-4">
                An error occurred.  Please try again or contact support.
            </v-alert>

            <p>
                Are you sure you would like to delete {{ toBeDeletedDestination }}
                as a lead destination for {{ dealer.name }}?
            </p>
            <template #actions>
                <styled-button
                    outline
                    class="ml-auto"
                    @click="displayDeleteLeadDestinationModal = false">
                    Close
                </styled-button>
                <styled-button
                    class="ml-3"
                    @click="deleteLeadDestination(selectedLeadDestination)">
                    Delete
                </styled-button>
            </template>
        </styled-dialog>

        <add-lead-destination-dialog
            v-if="displayAddDestinationDialog"
            :dealer="dealer"
            :active="displayAddDestinationDialog"
            @complete="onAddLeadDestination"
            @close="displayAddDestinationDialog = false" />

        <slot name="heading" />

        <div
            v-if="dealer.lead_destinations && dealer.lead_destinations.data.length"
            class="py-4 px-5">
            <div
                v-for="leadDestination in dealer.lead_destinations.data"
                :key="leadDestination.id"
                class="layout align-center pb-4">
                <div class="flex xs6">
                    <data-field v-if="leadDestination.lead_sender_class_name == WEBHOOK_LEAD_SENDER">
                        <template #label>
                            Webhook
                        </template>
                        {{ leadDestination.lead_sender_configuration.webhook_url }}
                    </data-field>
                    <data-field v-else>
                        <template #label>
                            Email
                        </template>
                        {{ leadDestination.lead_sender_configuration.email }}
                    </data-field>
                </div>
                <div class="flex xs4">
                    <data-field>
                        <template #label>
                            Type
                        </template>
                        <span class="text-uppercase">
                            {{ leadDestinationType(leadDestination) }}
                        </span>
                    </data-field>
                </div>
                <div class="flex xs2 text-xs-right">
                    <action-button
                        class="mx-2"
                        icon="trash"
                        @click="openDeleteLeadModal(leadDestination)" />
                </div>
            </div>
        </div>
        <div v-else>
            <slot name="no-destinations" />
        </div>

        <slot
            name="actions"
            :open-add-destination-dialog="openAddDestinationDialog" />
    </div>
</template>

<script>
import DataField from '@/components/globals/DataField';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import ActionButton from '@/components/globals/ActionButton';
import AddLeadDestinationDialog from '@/components/globals/AddLeadDestinationDialog';
import { XML_EMAIL_LEAD_SENDER, TEXT_EMAIL_LEAD_SENDER, WEBHOOK_LEAD_SENDER } from '@/helpers/globals';

export default {
    components: {
        AddLeadDestinationDialog,
        ActionButton,
        DataField,
        StyledDialog,
        StyledButton
    },
    props: {
        dealer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            displayAddDestinationDialog: false,
            displayDeleteLeadDestinationModal: false,
            selectedLeadDestination: null,
            error: null,
            XML_EMAIL_LEAD_SENDER,
            TEXT_EMAIL_LEAD_SENDER,
            WEBHOOK_LEAD_SENDER
        };
    },
    computed: {
        toBeDeletedDestination() {
            if (this.selectedLeadDestination?.lead_sender_class_name == this.WEBHOOK_LEAD_SENDER) {
                return this.selectedLeadDestination.lead_sender_configuration.webhook_url;
            } 

            if (this.selectedLeadDestination) {
                return this.selectedLeadDestination.lead_sender_configuration.email;
            }
            
            return null;
        }
    },
    methods: {
        openAddDestinationDialog() {
            this.displayAddDestinationDialog = true;
        },
        onAddLeadDestination(data) {
            // Pass updated event to the parent
            this.$emit('updated', data);
        },
        leadDestinationType(leadDestination) {
            switch (leadDestination.lead_sender_class_name) {
                case this.TEXT_EMAIL_LEAD_SENDER :
                    return 'Plain Text';
                case this.XML_EMAIL_LEAD_SENDER :
                    return 'ADF XML';
                case this.WEBHOOK_LEAD_SENDER :
                    return 'WEBHOOK';
            }
        },
        openDeleteLeadModal(leadDestination) {
            this.selectedLeadDestination = leadDestination;
            this.displayDeleteLeadDestinationModal = true;
        },
        async deleteLeadDestination(leadDestination) {
            this.error = null;
            try {
                const data = {
                    related: [{
                        related_object_key: leadDestination.id
                    }]
                };
                await this.$http.put(`dealers/${this.dealer.id}/lead_destinations/detach`, data);
                this.$emit('updated');
                this.selectedLeadDestination = null;
                this.displayDeleteLeadDestinationModal = false;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error deleting lead destination:', error);
                this.error = 'Error deleting lead destination';
            }
        }
    }
};
</script>
