<template>
    <div>
        <h2>Lead Destinations</h2>
        <p class="mb-5">
            Lead destinations determine where BuyerBridge should send leads
            when received through various sources like Facebook lead ads,
            our chatbot and API setups (e.g. using Zapier, etc).
        </p>
        <manage-dealer-lead-destinations
            :dealer="currentDealer"
            class="mb-5"
            @updated="onUpdateLeadDestinations">
            <template #actions="{ openAddDestinationDialog }">
                <div class="text-xs-center">
                    <styled-button
                        class="text-uppercase"
                        outline
                        @click="openAddDestinationDialog">
                        Add Lead Destination
                    </styled-button>
                </div>
            </template>
        </manage-dealer-lead-destinations>
        <div class="layout align-center">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                @click="$store.dispatch('goToNextStep')">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import ManageDealerLeadDestinations from '../../../globals/ManageDealerLeadDestinations';
import StyledButton from '../../../globals/StyledButton';
import BackButton from '../../../globals/BackButton';
import { mapState } from 'vuex';

export default {
    components: {
        BackButton,
        StyledButton,
        ManageDealerLeadDestinations
    },
    data() {
      return {};
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        })
    },
    methods: {
        onUpdateLeadDestinations() {
            this.$store.dispatch('updateCurrentDealer');
        }
    }
};
</script>
