<template>
    <styled-dialog v-model="isActive">
        <!-- Add Destination Start -->
        <template
            v-if="!complete"
            #heading>
            <h2>Add Lead Destination</h2>
        </template>
        <v-alert
            :value="error"
            type="error"
            transition="fade-transition"
            class="mb-4">
            An error occurred. Please try again or contact us for assistance.
        </v-alert>
        <v-form
            v-if="!complete"
            id="add-lead-destination-form"
            ref="form"
            v-model="valid"
            @submit.prevent="addLeadDestination">
            <h4 class="mb-2">
                Destination Type
            </h4>
            <v-radio-group
                v-model="leadType"
                :mandatory="true"
                row
                :rules="radioRules"
                class="styled-radio">
                <v-radio
                    label="ADF XML"
                    :value="XML_EMAIL_LEAD_SENDER"
                    color="primary" />
                <v-radio
                    label="Plain Text"
                    :value="TEXT_EMAIL_LEAD_SENDER"
                    color="primary" />
                <v-radio
                    label="Webhook"
                    :value="WEBHOOK_LEAD_SENDER"
                    color="primary" />
            </v-radio-group>
            <v-text-field
                v-if="leadType"
                v-model="destination"
                :label="
                    leadType == WEBHOOK_LEAD_SENDER
                        ? 'Add Webhook URL'
                        : 'Add Email Destination'
                "
                :rules="activeRules"
                class="styled-field mt-3" />
            <v-checkbox
                v-if="leadType"
                v-model="disclaimer"
                color="primary"
                :rules="activeRules"
                class="styled-checkbox my-3">
                <template #label>
                    By clicking this box you agree that you are responsible 
                    for verifying that leads are reaching this destination
                </template>
            </v-checkbox>
            <div class="layout justify-center">
                <styled-button
                    :loading="loading"
                    :disabled="!valid"
                    class="text-uppercase mt-3 mb-3">
                    Submit
                </styled-button>
            </div>
        </v-form>
        <template
            v-if="!complete"
            #actions>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('close')">
                Cancel
            </a>
        </template>
        <p
            v-if="leadType && !complete"
            class="my-3 text-center contact-style">
            Please contact
            <a href="mailto:support@buyerbridge.io">support@buyerbridge.io</a>
            for additional information or assistance with lead delivery
            testing procedures.
        </p>
        <!-- Add Destination End -->

        <!-- Completed Start -->
        <div
            v-if="complete"
            class="text-xs-center">
            <h2>Lead Destination Added!</h2>
            <p>
                The lead destination has been successfully added. You can close
                this dialog to continue.
            </p>
            <styled-button
                class="mx-auto"
                @click="$emit('close')">
                Close
            </styled-button>
        </div>
        <!-- Completed End -->
    </styled-dialog>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import {
    emailRules,
    requiredRules,
    radioRules,
    urlRules,
} from '@/helpers/validationRules';
import {
    XML_EMAIL_LEAD_SENDER,
    TEXT_EMAIL_LEAD_SENDER,
    WEBHOOK_LEAD_SENDER,
} from '@/helpers/globals';

export default {
    components: {
        StyledDialog,
        StyledButton,
    },
    props: {
        dealer: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isActive: false,
            destination: '',
            leadType: null,
            valid: false,
            loading: false,
            error: false,
            radioRules,
            complete: false,
            XML_EMAIL_LEAD_SENDER,
            TEXT_EMAIL_LEAD_SENDER,
            WEBHOOK_LEAD_SENDER,
            disclaimer: false,
        };
    },
    computed: {
        activeRules() {
            if (this.leadType == this.WEBHOOK_LEAD_SENDER) {
                return [...requiredRules, ...urlRules];
            }

            return [...requiredRules, ...emailRules];
        },
    },
    watch: {
        active(value) {
            this.isActive = value;
        },
    },
    created() {
        // Slightly delay in case we're activating when mounting to see animation
        setTimeout(() => {
            this.isActive = this.active;
        }, 200);
    },
    methods: {
        async addLeadDestination() {
            this.$refs.form.validate();

            if (!this.valid) {
                return;
            }

            this.loading = true;
            this.error = false;

            try {
                const params = {
                    lead_sender_class_name: this.leadType,
                    dealer_id: this.dealer.id,
                };

                if (this.leadType == this.WEBHOOK_LEAD_SENDER) {
                    params.lead_sender_configuration = {
                        webhook_url: this.destination,
                    };
                } else {
                    params.lead_sender_configuration = {
                        email: this.destination,
                    };
                }

                const url = `/dealers/${this.dealer.id}/lead_destinations`;

                const response = await this.$http.post(url, params);

                this.complete = true;

                // Combine the response and the params so consumers can
                // use the data (e.g. to update the UI)
                this.$emit('complete', {
                    ...response.data.data,
                    ...params,
                });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error adding lead destination:', error);
                this.error = true;
                this.$emit('error', error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.contact-style {
    font-size: 12px;
    color: $gray-primary;
    font-style: italic;
}
</style>